<template>
  <div>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <llavero-entrada-form
          :id="null"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </div>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import Data from './LlaveroEntradaData'
import { LLAVERO } from '@/utils/consts'
import LlaveroEntradaForm from './components/LlaveroEntradaForm'
import { get } from 'vuex-pathify'

export default {
  components: {
    LlaveroEntradaForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Entrada de llavero'
    },
    async submitForm () {
      await Data.insertMovLlaveroEntrada(
        this,
        this.routeParams.idllavero,
        this.usuarioIdempleado,
        this.formData.idmotivo_entrada,
        this.formData.fecha
      )
      await Data.updateLlavero(
        this,
        this.routeParams.idllavero,
        LLAVERO.estados.enTablero
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.llavero, this.routeParams.idllavero)
      this.$alert.showSnackbarSuccess('Entrada de llavero registrada')
      this.$appRouter.go(-1)
    }
  }
}
</script>
