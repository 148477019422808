export default {
  async insertMovLlaveroEntrada (Vue, idllavero, idempleado, idmotivoEntrada, fecha) {
    await Vue.$api.call(
      'movLlavero.insert',
      {
        values: {
          idllavero,
          idempleado,
          fecha,
          idmotivo_entrada_llavero: idmotivoEntrada,
        },
      }
    )
  },
  async updateLlavero (Vue, idllavero, estado) {
    await Vue.$api.call('llavero.update', { values: { idllavero, estado } })
  }
}
