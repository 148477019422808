<template>
  <b10-base>
    <v-text-field
      v-model="fecha"
      disabled
      label="Fecha"
    />
    <v-text-field
      v-model="hora"
      disabled
      label="Hora"
    />
    <b10-autocomplete
      v-model="form.idmotivo_entrada"
      :items="motivosEntrada"
      item-value="idmotivo_entrada_llavero"
      item-text="descripcion"
      label="Motivo de entrada"
      clearable
      :rules="formRules.idmotivo_entrada"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './LlaveroEntradaFormData'
import { currentDateTime } from '@/utils/date'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        fecha: currentDateTime(),
        idmotivo_entrada: null,
      },
      formRules: {
        idmotivo_entrada: [
          v => !!v || 'Campo requerido'
        ]
      },
      motivosEntrada: [],
      fecha: null,
      hora: null
    }
  },
  async created () {
    this.motivosEntrada = await Data.selectMotivoEntradaLlavero(this)
    if (this.motivosEntrada.length === 1) {
      this.$set(this.form, 'idmotivo_entrada', this.motivosEntrada[0].idmotivo_entrada_llavero)
    }
    this.fecha = this.$options.filters.shortDate(this.form.fecha)
    this.hora = this.$options.filters.shortTime(this.form.fecha)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  },
}
</script>
